module.exports = () => {
    class PopUp {
        constructor() {
            this.windowData = {
                scrollTop: null,
                htmlHeight: null
            };
            this.body = document.querySelector(`body`);
            this.activePopUp = [];

            this.registerEventHandlers();

            window.showPopUp = (popUpId) => {
                const popUp = document.querySelector(`#${popUpId}`);

                if (popUp && !popUp.classList.contains(`active`)) {
                    this.showPopUp(popUp);
                }
            };
        }

        registerEventHandlers() {
            const moduleName = `popUp`;

            Array.from(document.querySelectorAll(`.js-pop-up`)).forEach((popUp) => {
                if (popUp.dataset[`${moduleName}Init`] === `true`) return;
                popUp.dataset[`${moduleName}Init`] = `true`;

                popUp.addEventListener(`click`, (event) => {
                    const btnClose = event.target.closest(`.js-pop-up-btn-close`);
                    if (!btnClose) return;

                    event.preventDefault();
                    event.stopPropagation();

                    this.hidePopUp(popUp);
                });

                popUp.addEventListener(`click`, (event) => {
                    const insides = event.target.closest(`.js-pop-up-insides`);
                    if (insides) return;

                    event.stopPropagation();

                    this.hidePopUp(popUp);
                });

                new MutationObserver(() => {
                    this.putFocusOnTheFirstInput(popUp);
                    this.centeringPopUp(popUp);
                    this.callOtherModules();

                    popUp.classList.remove(`loading`);
                }).observe(popUp, {
                    childList: true,
                    characterData: true,
                    subtree: true
                });
            });

            Array.from(document.querySelectorAll(`.js-pop-up-btn-open`)).forEach((popUpBtnOpen) => {
                if (popUpBtnOpen.dataset[`${moduleName}Init`] === `true`) return;
                popUpBtnOpen.dataset[`${moduleName}Init`] = `true`;

                popUpBtnOpen.addEventListener(`click`, (event) => {
                    const btnOpen = event.target.closest(`.js-pop-up-btn-open`);
                    if (!btnOpen) return;

                    event.preventDefault();

                    const popUpId = btnOpen.dataset[`popUpId`];
                    const popUp = document.querySelector(`#${popUpId}`);

                    if (popUp) {
                        this.showPopUp(popUp, popUpBtnOpen);
                    }
                });
            });

            window.addEventListener(`resize`, () => {
                if (this.activePopUp.length) {
                    this.centeringPopUp(this.activePopUp[this.activePopUp.length -1]);
                }
            });

            document.addEventListener(`keydown`, (event) => {
                if (event.keyCode === 27 && this.activePopUp.length) {
                    event.preventDefault();

                    this.hidePopUp((this.activePopUp[this.activePopUp.length -1]));
                }
            });
        }

        showPopUp(popUp, popUpBtnOpen) {
            if (!this.activePopUp.length) {
                this.updateWindowData();
            }

            const url = popUp.dataset[`url`];
            const hasOnclick = !!popUpBtnOpen ? popUpBtnOpen.getAttribute(`onclick`) : null;

            if (url) {
                this.getPopUpByAjaxAndInsert(popUp, url);
            }

            if (hasOnclick) {
                popUp.innerHTML = ``;
                popUp.classList.add(`loading`);
            }

            popUp.classList.add(`active`);

            if (!url && !hasOnclick) {
                this.putFocusOnTheFirstInput(popUp);
            }

            if (this.hasVerticalScroll() && !this.activePopUp.length) {
                this.body.style.overflowY = `scroll`;
            }

            if (!this.activePopUp.length) {
                this.createAndAddStylesToDom();
            }

            if (!url && !hasOnclick) {
                this.centeringPopUp(popUp);
            }

            this.activePopUp.push(popUp);
        }

        hidePopUp(popUp) {
            popUp.classList.remove(`active`);

            if (this.activePopUp.length === 1) {
                this.body.style.overflowY = `auto`;

                document.body.classList.remove(`styles-for-pop-up`);

                window.scrollBy(0, this.windowData.scrollTop);
            }

            this.activePopUp.splice(this.activePopUp.indexOf(popUp), 1);
        }

        createAndAddStylesToDom() {
            const styles = `
                <style id="styles-for-pop-up">
                    .styles-for-pop-up {
                        position: fixed;
                        top: -${this.windowData.scrollTop}px;
                        width: 100%;
                        height: ${this.windowData.htmlHeight}px;
                    }
                </style>
            `;

            if (document.querySelector(`#styles-for-pop-up`)) {
                document.querySelector(`#styles-for-pop-up`).remove();
            }

            document.body.insertAdjacentHTML(`beforeend`, styles);
            document.body.classList.add(`styles-for-pop-up`);
        }

        getPopUpByAjaxAndInsert(popUp, url) {
            const formData = new FormData();
            const xhr = new XMLHttpRequest();

            formData.append(`id`, popUp.getAttribute(`id`));

            popUp.innerHTML = ``;
            popUp.classList.add(`loading`);

            xhr.open(`POST`, url);
            xhr.setRequestHeader(`X-Requested-With`, `XMLHttpRequest`);
            xhr.onreadystatechange = () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    popUp.classList.remove(`loading`);

                    if (xhr.status === 200) {
                        popUp.innerHTML = xhr.responseText;

                        this.putFocusOnTheFirstInput(popUp);
                        this.centeringPopUp(popUp);

                        this.callOtherModules();
                    } else {
                        alert(`Error! Status Code: ${xhr.status}!`);
                    }
                }
            };
            xhr.onerror = () => {
                alert(`An error occurred during the transaction!`);
            };
            xhr.send(formData);
        }

        putFocusOnTheFirstInput(popUp) {
            if (popUp.dataset[`popUpPutFocus`] === `false`) return;

            setTimeout(() => {
                if (!(document.body.classList.contains(`mobile`))) {
                    Array.from(popUp.querySelectorAll(`input, textarea`)).every((input) => {
                        if (input.offsetParent !== null && input.value === ``) {
                            input.focus();
                            return false;
                        } else {
                            return true;
                        }
                    });
                }
            }, 200);
        }

        hasVerticalScroll() {
            return window.innerWidth > document.documentElement.clientWidth;
        }

        centeringPopUp(popUp) {
            if (!popUp.querySelector(`.js-pop-up-insides`)) return;

            this.centeringPopUpAfterLoadingImages(popUp);

            const popUpInsides = popUp.querySelector(`.js-pop-up-insides`);
            const popUpInsidesHeight = popUpInsides.offsetHeight;
            const windowHeight = window.innerHeight;

            if (windowHeight - 120 > popUpInsidesHeight) {
                popUpInsides.style.marginTop = `${(windowHeight - popUpInsidesHeight) / 2}px`;
            } else {
                popUpInsides.removeAttribute(`style`);
            }
        }

        centeringPopUpAfterLoadingImages(popUp) {
            const notUploadedImagesInPopUp = Array.from(popUp.querySelectorAll(`img`)).filter(image => !image.complete);

            if (!notUploadedImagesInPopUp.length) return;

            Array.from(notUploadedImagesInPopUp).forEach((imageInPopUp) => {
                const image = new Image();

                image.onload = () => {
                    this.centeringPopUp(popUp);
                };

                image.src = imageInPopUp.getAttribute(`src`);
            });
        }

        updateWindowData() {
            this.windowData.scrollTop = (document.documentElement.scrollTop || document.body.scrollTop);
            this.windowData.htmlHeight = document.querySelector(`html`).offsetHeight;
        }

        callOtherModules() {

        }
    }



    new PopUp();
};