var $ = window.jQuery = window.$ = require('jquery');

var initCustomWidgets = function () {
	require('./modules/navInit')();
	require('./modules/promoSliderInit')();
	require('./modules/responsiveTableInit')();
	require('./modules/magnificInit')();
	require('./modules/relatedSliderInit')();
	require('./modules/scrollToInit')();
	require('./modules/contactsToggleInit')();
	require('./modules/headerSearchInit')();

	require('./modules/pop-up')();
	require('./modules/transfer-data')();
	require('./modules/input-mask')();

	require('./pointsMap/pointsMapInit.jsx')();
};

$(initCustomWidgets);
