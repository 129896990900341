const Inputmask = require('inputmask');

const moduleName = `inputMask`;

module.exports = () => {
    window.moduleInputMask = () => {
        Array.from(document.querySelectorAll(`[type="tel"]`)).forEach((wrapper) => {
            if (wrapper.dataset[`${moduleName}Init`] === `true`) return;
            wrapper.dataset[`${moduleName}Init`] = `true`;



            new Inputmask({
                mask: `+7 (999) 999-99-99`,
                placeholder: `_`,
                clearIncomplete: true,
                showMaskOnHover: false
            }).mask(wrapper);



            wrapper.addEventListener(`input`, () => {
                if (wrapper.inputmask) {
                    if (wrapper.inputmask.isComplete()) {
                        addClass();
                    } else {
                        removeClass();
                    }
                } else if (wrapper.value !== ``) {
                    addClass();
                } else {
                    removeClass();
                }
            });



            function addClass() {
                wrapper.classList.add(`inputmask-complete`);
            }

            function removeClass() {
                wrapper.classList.remove(`inputmask-complete`);
            }



        });
    };



    window.moduleInputMask();
};