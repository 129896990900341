const moduleName = `transferData`;

module.exports = () => {
	Array.from(document.querySelectorAll(`.js-transfer-data`)).forEach((wrapper) => {
		if (wrapper.dataset[`${moduleName}Init`] === `true`) return;
		wrapper.dataset[`${moduleName}Init`] = `true`;



		const elementId = wrapper.dataset[`elementId`];
		const elementValue = wrapper.dataset[`elementValue`];



		wrapper.addEventListener(`click`, () => {
			const element = document.querySelector(`#${elementId}`);

			if (element && elementValue) {
				element.value = elementValue;
			}
		});



	});
};